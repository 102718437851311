<template>
  <v-card flat>
    <v-card-text class="d-flex align-center justify-center pa-16" v-if="initLoading">
      <v-progress-circular
          color="main"
          indeterminate
      />
    </v-card-text>
    <v-card-text
        v-else
    >
      <v-row
          class="mb-6"
          v-for="(item,index) in stats"
          :key="index"
          type="flex" align="center" justify="space-between">
        <v-col :cols="12">
          <div class="text-h6">
            {{ item.title }}
          </div>
          <v-divider/>
        </v-col>

        <v-col v-for="(item2,index2) in item.items"
               :key="index2"
               :lg="4"
               :cols="12"
        >

          <v-card
              link
              :to="item2.link"
              :class="['chart-card','card-'+item.id]">
            <v-card-text class="d-flex align-center justify-center flex-column">
              <div class="text-h2 mb-3 white--text">
                {{ item2.value }}
              </div>
              <div class="white--text text-body-2">
                {{ item2.name }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import {fetchData} from "@Newfiling/module-dashboard/src/api";
import {addCommas} from "@persian-tools/persian-tools";
import {getPermissions} from "@Newfiling/api";

export default {
  name: 'Desktop',
  data() {
    return {
      initLoading: true,
      stats: {
        users: {
          title: "کاربران",
          id: 1,
          items: {
            numberofusers: {
              name: "تعداد کاربران",
              value: 0,
              link: "/panel/users/list",
            },
            numberofuserstoday: {
              name: "تعداد کاربران امروز",
              value: 0,
            },
            Numberofyesterday: {
              id: 3,
              name: "تعداد کاربران دیروز",
              value: 0,
            },
            membersofthemonth: {
              id: 4,
              name: "تعداد کاربران این ماه",
              value: 0,
            },
            numberofonlineusers: {
              id: 5,
              name: "تعداد کاربران آنلاین",
              value: 0,
              link: "/panel/users/online"
            },
           
          }
        },
        files: {
          id: 2,
          title: "آگهی ها",
          items: {
            numberoffiles: {
              id: 1,
              name: "تعداد آگهی ها",
              value: 0,
              link: "/panel/files/newfiling-list",
            },
            numberoffilestoday: {
              id: 2,
              name: "تعداد آگهی های امروز",
              value: 0,
            },
            numberoffilesyesterday: {
              id: 3,
              name: "تعداد آگهی های دیروز",
              value: 0,
            },
            numberoffilesmonth: {
              id: 4,
              name: "تعداد آگهی های ماه",
              value: 0,
            },
            Numberofunverifiedfiles: {
              id: 5,
              name: " تعداد آگهی های تایید نشده",
              value: 0,
            },
          }
        },
        applications: {
          id: 3,
          title: "درخواست ها",
          items: {
            NumberofRequests: {
              id: 1,
              name: "تعداد درخواست ها",
              value: 0,
              link: "/panel/demands/list",
            },
            NumberofRequeststoday: {
              id: 2,
              name: "تعداد درخواست های امروز",
              value: 0,
            },
            NumberofRequestsyesterday: {
              id: 3,
              name: "تعداد درخواست های دیروز",
              value: 0,
            },
            NumberofRequestsmonth: {
              id: 4,
              name: "تعداد درخواست های ماه",
              value: 0,
            },
            NumberofunverifiedRequests: {
              id: 5,
              name: "تعداد درخواست های تایید نشده",
              value: 0,
            },
          }
        },
        payments: {
          id: 4,
          title: "تراکنش ها",
          items: {
            numberofpayments: {
              id: 1,
              name: "تعداد همه پرداختها",
              value: 0,
              link: "/panel/payments/list"
            },
            successfulNumberofpayments: {
              id: 2,
              name: "تعداد تراکنش های موفق",
              value: 0,
            },
            numberofunsuccessfultransactions: {
              id: 3,
              name: "تعداد تراکنش های ناموفق",
              value: 0,
            },
            numberofpaymentstoday: {
              id: 4,
              name: "تعداد پرداختهای امروز",
              value: 0,
            },
            successfulNumberofpaymentstoday: {
              id: 5,
              name: "تعداد پرداختهای موفق امروز",
              value: 0,
            },
            unsuccessfulNumberofpaymentstoday: {
              id: 6,
              name: "تعداد پرداختهای ناموفق امروز",
              value: 0,
            },
            successfulNumberofyesterday: {
              id: 7,
              name: " تعداد موفق دیروز",
              value: 0,
            },
            unsuccesssfulNumberofyesterday: {
              id: 6,
              name: "تعداد ناموفق دیروز",
              value: 0,
            },
            successfulNumberofpaymentsmonth: {
              id: 9,
              name: "تعداد پرداختهای موفق",
              value: 0,
            },
            unsuccesssfulNumberofpaymentsmonth: {
              id: 10,
              name: "تعداد پرداخت های ناموفق ماه",
              value: 0,
            },
            numberofpaymentsmonth: {
              id: 11,
              name: "تعداد پرداختهای ماه",
              value: 0,
            },
            sumpayment: {
              id: 12,
              name: "مجموع پرداخت ها",
              value: 0,
            },
          }
        },
        visitors: {
          title: "بازدید ها",
          id: 5,
          items: {
            numberofVisit: {
              name: "تعداد بازدید ها",
              value: 0,
            },
            numberofVisittoday: {
              name: "تعداد بازدید های امروز",
              value: 0,
            },
            numberofVisityesterday: {
              id: 3,
              name: "تعداد بازدید های  دیروز",
              value: 0,
            },
            numberofVisitmonth: {
              id: 4,
              name: "تعداد بازدید های  این ماه",
              value: 0,
            },

          }
        }
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.initLoading = true;
      try {
        const res = (await fetchData())?.data || {
          message: {
            membersofthemonth: 25,
            numberofRequests: 5,
            numberofRequestsmonth: 5,
            numberofRequeststoday: 0,
            numberofRequestsyesterday: 1,
            numberoffiles: 11,
            numberoffilesmonth: 11,
            numberoffilestoday: 0,
            numberoffilesyesterday: 0,
            numberofonlineusers: 0,
            numberofpayments: 11,
            numberofpaymentsmonth: 11,
            numberofpaymentstoday: 0,
            numberofpaymentyesterday: 0,
            numberofunsuccessfultransactions: 0,
            numberofunverifiedRequests: 0,
            numberofunverifiedfiles: 10,
            numberofusers: 14,
            numberofuserstoday: 2,
            numberofyesterday: 0,
            successfulNumberofpayments: 0,
            successfulNumberofpaymentsmonth: 2,
            successfulNumberofpaymentstoday: 0,
            successfulNumberofyesterday: 0,
            unsuccessfulNumberofpaymentstoday: 0,
            unsuccesssfulNumberofpaymentsmonth: 9,
            unsuccesssfulNumberofyesterday: 0,
            sumpayment: 0,
            numberofVisit: 0,
            numberofVisittoday: 0,
            numberofVisityesterday: 0,
            numberofVisitmonth: 0,
          }
        };
        this.stats.users.items.numberofonlineusers.value = addCommas(res.message?.numberofonlineusers);
        this.stats.users.items.membersofthemonth.value = addCommas(res.message?.membersofthemonth);
        this.stats.users.items.numberofusers.value = addCommas(res.message?.numberofusers);
        this.stats.users.items.numberofuserstoday.value = addCommas(res.message?.numberofuserstoday);
        this.stats.users.items.Numberofyesterday.value = addCommas(res.message?.numberofyesterday);

        this.stats.files.items.numberoffiles.value = addCommas(res.message?.numberoffiles);
        this.stats.files.items.numberoffilestoday.value = addCommas(res.message?.numberoffilestoday);
        this.stats.files.items.numberoffilesyesterday.value = addCommas(res.message?.numberoffilesyesterday);
        this.stats.files.items.numberoffilesmonth.value = addCommas(res.message?.numberoffilesmonth);
        this.stats.files.items.Numberofunverifiedfiles.value = addCommas(res.message?.numberofunverifiedfiles);

        this.stats.applications.items.NumberofRequests.value = addCommas(res.message?.numberofRequests);
        this.stats.applications.items.NumberofRequeststoday.value = addCommas(res.message?.numberofRequeststoday);
        this.stats.applications.items.NumberofRequestsyesterday.value = addCommas(res.message?.numberofRequestsyesterday);
        this.stats.applications.items.NumberofRequestsmonth.value = addCommas(res.message?.numberofRequestsmonth);
        this.stats.applications.items.NumberofunverifiedRequests.value = addCommas(res.message?.numberofunverifiedRequests);

        this.stats.payments.items.numberofpayments.value = addCommas(res.message?.numberofpayments);
        this.stats.payments.items.successfulNumberofpayments.value = addCommas(res.message?.successfulNumberofpayments);
        this.stats.payments.items.numberofunsuccessfultransactions.value = addCommas(res.message?.numberofunsuccessfultransactions);
        this.stats.payments.items.numberofpaymentstoday.value = addCommas(res.message?.numberofpaymentstoday);
        this.stats.payments.items.successfulNumberofpaymentstoday.value = addCommas(res.message?.successfulNumberofpaymentstoday);
        this.stats.payments.items.unsuccessfulNumberofpaymentstoday.value = addCommas(res.message?.unsuccessfulNumberofpaymentstoday);
        this.stats.payments.items.successfulNumberofyesterday.value = addCommas(res.message?.successfulNumberofyesterday);
        this.stats.payments.items.unsuccesssfulNumberofyesterday.value = addCommas(res.message?.unsuccesssfulNumberofyesterday);
        this.stats.payments.items.successfulNumberofpaymentsmonth.value = addCommas(res.message?.successfulNumberofpaymentsmonth);
        this.stats.payments.items.unsuccesssfulNumberofpaymentsmonth.value = addCommas(res.message?.unsuccesssfulNumberofpaymentsmonth);
        this.stats.payments.items.numberofpaymentsmonth.value = addCommas(res.message?.numberofpaymentsmonth);
        this.stats.payments.items.sumpayment.value = addCommas(res.message?.sumpayment);


        this.stats.visitors.items.numberofVisit.value = addCommas(res.message?.numberofVisit);
        this.stats.visitors.items.numberofVisittoday.value = addCommas(res.message?.numberofVisittoday);
        this.stats.visitors.items.numberofVisityesterday.value = addCommas(res.message?.numberofVisityesterday);
        this.stats.visitors.items.numberofVisitmonth.value = addCommas(res.message?.numberofVisitmonth);

      } catch (e) {
        console.log(e)
      }
      this.initLoading = false;
    }
  },
  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
    ])
  },
}
</script>

<style>

.chart-card.card-1 {
  background-color: #009cc6 !important;

}

.chart-card.card-2 {
  background-color: #00799b !important;

}

.chart-card.card-3 {
  background-color: #657fff !important;

}

.chart-card.card-4 {
  background-color: #00a50d !important;
}

.chart-card.card-5 {
  background-color: #FDBB2D !important;
}

.chart-card.card-6 {
  background-color: #fcff9e !important;
}

.chart-card.card-7 {
  background-color: #3ad59f !important;
}

.chart-card.card-8 {
  background-color: #3a47d5 !important;
}

.chart-card.card-9 {
  background-color: #515ada !important;
}

.chart-card.card-10 {
  background-color: #d53369 !important;
}

.chart-card.card-11 {
  background-color: #0700b8 !important;
}

.chart-card.card-12 {
  background-color: #9ebd13 !important;
}

.chart-card.card-13 {
  background-color: #4b6cb7 !important;
}
</style>
